import { template as template_3c1f7e73da2f4e0c9f9f67e82bfae4c7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_3c1f7e73da2f4e0c9f9f67e82bfae4c7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
