import { template as template_ed135c65a41b4a949f06d0966b117f20 } from "@ember/template-compiler";
const FKLabel = template_ed135c65a41b4a949f06d0966b117f20(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
